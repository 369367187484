import React, { useState, useEffect } from 'react';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { THEMES } from '@manulife/mux';
import { PageBodyWrapper, Header, Footer } from './components'
import { Grid, Container } from '@material-ui/core';
import { getQueryParams } from './utils/routeUtils';
import { MyContext } from './Context/MyContext';
import JsonConfig from './utils/jsonConfig.json';
import LeftNavigationPanel from './components/RPS/LeftNavigation/LeftNavigationPanel';
import useAdobeDataLayer from "./hooks/useAdobeDataLayer";


function App(props) {
  const { addAdobeScript } = useAdobeDataLayer();

  const [notification, setNotification] = useState({
    isVisible: false,
    type: "",
    title: "",
    message1: "",
    message2: "",
  });

  function iniFrame() {
    if (window.self !== window.top) {
      console.log("In iFrame");
      return true;
    } else {
      console.log("Not in iFrame");
      return false;
    }
  }

  useEffect(() => {
    addAdobeScript();
  }, []);

  if (iniFrame()) {
    return null;
  }

  const {appId} = getQueryParams();

  return (
    <ThemeProvider theme={THEMES.johnhancockTheme}>
      <MyContext.Provider value={{notification, setNotification}}>
        <div class='app-container'>
          <LeftNavigationPanel />
          <div class='body-container'>
            <Container className="app-content">
              <Grid container spacing={8}>
                <Grid item xs={8}>
                  <Header appId={appId}/>
                </Grid>
              </Grid>
              <PageBodyWrapper />
            </Container>
            <Footer appId={appId}/>
          </div>
        </div>
      </MyContext.Provider>
    </ThemeProvider>
  );
}

export default App;
