import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Button } from '@manulife/mux';
import {getQueryParams} from "../../utils";
import JsonConfig from '../../utils/jsonConfig.json';
import Utility from '../../utils/utility';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    errorHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    textLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "22px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    contactLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "26px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    closeLabelStyles: {
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "13px",
        lineHeight: "18px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    buttonPadding: {
        paddingTop: "25px",
        paddingBottom: "25px"
    },
    buttonContainer: {

        "& button": {
            fontWeight: "500",
            fontFamily: font,
            fontSize: "18px",
            lineHeight: "22px",
            fontStyle: "normal",
            textAlign: "center",
            color: "#FFFFFF",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    contactSeparation: {
        paddingBottom: "20px"
    }
}));

const ErrorPage = (props) => {

    const {setSessionTimeoutEnable, isReview} = props;

    useEffect(()=> {
        setSessionTimeoutEnable(false);
        Utility.scrollToTop();
    }, [])

    let { appId } = getQueryParams();

    
    let supportNumber = JsonConfig.call_center_number[appId] ? JsonConfig.call_center_number[appId] : JsonConfig.call_center_number.default;
    let startTime = JsonConfig.time[appId] ? JsonConfig.time[appId].start_time : JsonConfig.time.default.start_time;
    let endTime = JsonConfig.time[appId] ? JsonConfig.time[appId].end_time : JsonConfig.time.default.end_time;;
    let validatePhoneNumberRegex = /^[0-9\-/?]*$/;
    
    const redirectToHome = () => {
        let redirectUrl = JsonConfig.static_redirect_url[appId] || JsonConfig.static_redirect_url.default;
        Utility.redirectUrl(redirectUrl)
    };
    const domainName = JsonConfig.domain_name[appId] || JsonConfig.domain_name.default;
    const isVitality = appId === JsonConfig.app_id.VITALITY;

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container md={8} xs={12} className={classes.gridSeparation}>
                <span className={classes.errorHeaderStyles}>{isReview && appId === JsonConfig.app_id.LTC ? "You're almost there" :"We’re having trouble setting up your account online"}.</span>
            </Grid>
            <Grid container md={8} xs={12} className={classes.gridSeparation}>
                <span className={classes.subheaderStyles}>{isReview && appId === JsonConfig.app_id.LTC ? "Please give us a call to complete your registration" : "Please give us a call so we can assist you"}.</span>
            </Grid>

            <Grid container xs={12} className={classes.contactSeparation}>
                <Grid item xs={12}>
                    <span className={classes.textLabelStyles}>Customer Center</span>
                </Grid>
                <Grid item xs={12}>
                <a href={`tel:${validatePhoneNumberRegex.test(supportNumber) ? supportNumber : ""}`} className={classes.contactLabelStyles}><u>{supportNumber}</u></a>
                </Grid>
            </Grid>
            <Grid container xs={12} className={classes.contactSeparation}>
                <Grid item xs={12}>
                    <span className={classes.textLabelStyles}>Monday - Friday, {startTime} - {endTime} ET</span>
                </Grid>
                <Grid item xs={12}>
                    <span className={classes.closeLabelStyles}>Closed on Saturday, Sunday and public holidays</span>
                </Grid>
            </Grid>
            <Grid container xs={12} className={classes.buttonPadding}>
                <Grid item md={8} xs={12} className={classes.buttonContainer}>
                    <Button
                        customStyle={{
                            buttonStyle: {
                                width: isVitality ? "312px" : "300px",
                                height: "70px"
                            }
                        }}
                        name="ReturnToJHButton"
                        id="ReturnToJHButton"
                        onClick={e => redirectToHome()}>Return to {domainName}</Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default ErrorPage
