import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Button } from '@manulife/mux';
import JsonConfig from '../../utils/jsonConfig.json';
import Utility from '../../utils/utility';


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "15px"
    },
    errorHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    textLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "22px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    contactLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "26px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    closeLabelStyles: {
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "13px",
        lineHeight: "18px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    buttonPadding: {
        paddingTop: "25px",
        paddingBottom: "25px"
    },
    buttonContainer: {

        "& button": {
            fontWeight: "500",
            fontFamily: font,
            fontSize: "18px",
            lineHeight: "22px",
            fontStyle: "normal",
            textAlign: "center",
            color: "#FFFFFF",

            minWidth: "250px",
            width: "252px",
            height: "60px",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    contactSeparation: {
        paddingBottom: "20px"
    }
}));

const DTCRegisterErrorPage = (props) => {

    useEffect(()=> {
        props.setSessionTimeoutEnable(false)
        Utility.scrollToTop();
    }, [])

    const redirectToRegister = () => {
        window.location.href = "https://www.johnhancock.com/index.html";
        // let redirectUrl = JsonConfig.static_redirect_url[props.appId] || JsonConfig.static_redirect_url.default;
        // window.location.href = redirectUrl;
    };

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container md={12} xs={12} className={classes.gridSeparation}>
                <span className={classes.errorHeaderStyles}>Sorry, you're in the wrong place. But we can help! </span>
            </Grid>
            <Grid container md={12} xs={12} className={classes.gridSeparation}>
                <span className={classes.subheaderStyles}>Please press "Continue" to be redirected to the correct site. </span>
            </Grid>
            <Grid container xs={12} className={classes.buttonPadding}>
                <Grid item md={8} xs={12} className={classes.buttonContainer}>
                    <Button
                        name="redirectRegisterButton"
                        id="redirectRegisterButton"
                        onClick={e => redirectToRegister()}>Continue</Button>
                </Grid>
            </Grid>


        </div>
    );
};

export default DTCRegisterErrorPage
