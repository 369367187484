import axios from 'axios';

function checkMaintenance(setMaintenanceCall, setErrorType, setMaintenanceDetails) {
    
    return new Promise((resolve, reject)=> {
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': true
        };
        axios.post(
            process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/message` : "http://localhost:8080/message",
            { headers }).then(response => {
                setMaintenanceCall(false)
               // console.log(response.data.message)
                setErrorType(response.data.message)
                setMaintenanceDetails(response.data?.details);
                resolve(true);
                return
            }).catch(error => {
                setMaintenanceCall(false)
                console.log("Error ========>", error);
                console.log(error.message);
                if(error.message === "Network Error") {
                    setErrorType("Technical")
                }
                resolve(true);
            })
    });
}

export default checkMaintenance;

