import axios from 'axios';

const verifyUser = (formValues, setErrorScenario, systemErrorCount, setSystemErrorCount, setPage, setIsAwaitingApiCall, setErrorType, modalHook, setGuid, setDtcRegisterError, setAlreadyRegistered, setAnnuities, setAuthenticationFlow, adobeDataLayer) => {
    const setModalOpen = modalHook[1];
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true
    };
    setIsAwaitingApiCall(true)
    axios.post(
        process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/verifyUser` : "http://localhost:8080/verifyUser",
        {
            "dateofbirth": formValues.day + "/" + formValues.month + "/" + formValues.year,
            "emailaddress": formValues.emailAddress,
            "firstname": formValues.firstName.trim(),
            "lastname": formValues.lastName.trim(),
            "phonenumber": formValues.phoneNumber,
            "ssn": formValues.lastSixDigitSSN.replace(/\D/g, ''),
            "appid": formValues.appId,
            "paperlesspreference": formValues.goPaperless ? true : false
        },
        { headers }).then(response => {
            setIsAwaitingApiCall(false)
            setModalOpen(false)
            console.log("verifyUser api==", response.data.code, response.data.message);
            let errorPageCodes = ["9009", "9027", "9041", "9048", "9036", "9037", "9046", "9047", "9011", "9055", "9038", "9042", "9057"];
            let dtcRegisterErrorCodes = ["9050", "9040", "9028"];
            let systemErrorCodes = ["9007", "9008", "9053", "9031", "9032"];

            if (response.data.code === "1001") {
                console.log("User verified: Fresh registration")
                setGuid(response.data.details.requestid)
                setSystemErrorCount(0);
                setPage(1)
            } else if (errorPageCodes.includes(response.data.code)) {
                setErrorScenario(true)
            } else if (response.data.code === "9035") {
                adobeDataLayer.registrationUnsuccessfull('accountAlreadyExists');
                setAlreadyRegistered(true)
            } else if (dtcRegisterErrorCodes.includes(response.data.code)) {
                setDtcRegisterError(true)
            } else if (response.data.code === "3000") {
                setSystemErrorCount(0);
                setAuthenticationFlow(true);
                setGuid(response.data.details.requestid)
                setPage(4);
            } else if (systemErrorCodes.includes(response.data.code)) {
                setSystemErrorCount(systemErrorCount + 1)
                setPage(0)
            } else if (response.data.code === "9054") {
                setAnnuities({isVisible: true, from: "UserInfoPage"});
            } else if (response.data.code === "9056") {
                let url = response.data.details;
                if(url?.startsWith("https://crverifyidentity.registration-dev.johnhancock.com") || 
                    url?.startsWith("https://crverifyidentity.registration-tst.johnhancock.com") ||
                    url?.startsWith("https://crverifyidentity.registration-uat.johnhancock.com") ||
                    url?.startsWith("https://crverifyidentity.registration.johnhancock.com") ||
                    url?.startsWith("http://localhost:3001")){
                    window.open(url, "_self");
                }
            } else {
                setSystemErrorCount(0);
                setPage(1)
            }
        }).catch(error => {
            setIsAwaitingApiCall(false)
            setModalOpen(false)
            console.log("Error ========>", error);
            if (error.message === "Network Error") {
                setErrorType("Technical")
            } else{
                setSystemErrorCount(systemErrorCount + 1)
            }
        })
};

export default verifyUser;
