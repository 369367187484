import axios from 'axios'
import Utility from '../utils/utility';

const postCall = (formValues, appId, setLoginHook, setIsAwaitingApiCall,isAuthenticationFlow) => {
    let data = {
        "username": isAuthenticationFlow ? formValues.authUserId: formValues.userName,
        "appId": appId
    };
    setIsAwaitingApiCall(true)
    axios(
        {
            method: 'post',
            data: data,
            url:  process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/postCall`: "http://localhost:8080/postCall",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': true,
                'Accept': 'application/json'
            }
        }
    ).then(
        (response) => {
            setIsAwaitingApiCall(false)
            if (response.data.code !== 9016 && response.data.code !== 9001) {
                const token = response.data.details.token;
                let redirectUrl = response.data.details.postUrl;
                   if (token != null) {
                    console.log("call post")
                    console.log("token", token);
                    console.log("Post Url", redirectUrl);
                    if(redirectUrl.endsWith("jwt=")){
                        redirectUrl = redirectUrl+token;
                    }
                    console.log("redirectUrl=", redirectUrl);
                    autoLoginForm(redirectUrl, token, appId);
                }
            } else {
                console.log(response.data.message)
                // Redirect to Login button page
                setLoginHook(true)
                return
            }
        }
    )

    function autoLoginForm(url, jwtToken, applicationId) {

        

        let form = document.getElementById("postCallForm");
        form.action = Utility.sanitize(url);

        let jwtEle = document.getElementById("postCallJwt");
        jwtEle.value = Utility.sanitize(jwtToken);

        let appIdEle = document.getElementById("postCallAppId");
        appIdEle.value = Utility.sanitize(applicationId);
        form.submit();

        // let form = document.createElement("form");
        // let jwt = document.createElement("input");
        // let appId = document.createElement("input");

        // form.method = "POST";
        // form.action = url;

        // jwt.type = "hidden";
        // jwt.value = jwtToken;
        // jwt.id = "jwt";
        // form.appendChild(jwt);

        // appId.type = "hidden";
        // appId.value = applicationId;
        // appId.id = "appId";
        // form.appendChild(appId);
    
        // document.body.appendChild(form);
        // form.submit();
    }
}

export default postCall