import React, { useEffect, useState } from 'react'
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@manulife/mux';
import { getCustomization } from '../../utils';
import { Progress } from '@manulife/mux';
import Utility from '../../utils/utility';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    successHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0
    },
    successBodyStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0
    },
    inlineDisplay: {
        display: "flex",
        height:"100%",
        marginLeft:"8px"
    },
    inlineDisplay2:{
        display: "inline-block",
        marginTop: "10px",
        marginBottom:"10px",
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom:"20px",
        display:"inline-block",
        marginLeft:"8px"

    },
    buttonContainer:{
        display:"flex",
        width: "250px",
        height: "60px",
        marginTop: "-7px",

        "& button": {
            fontWeight: "500",
            fontFamily: font,
            fontSize: "18px",
            lineHeight: "22px",
            fontStyle: "normal",
            textAlign: "center",
            color: "#FFFFFF",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }

        }
    },
    userNotesStyles: {
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    userNotesSeparation: {
        marginTop: "16px",
        marginLeft: "10px",
        border: "1px solid #EDEDED",
        boxSizing: "border-box",
        [theme.breakpoints.down(960)]: {
            marginLeft: "25px",                  
        }
    }
}))

const SuccessPage = props => {
    const { successBody, successCTA } = getCustomization(props.formValues.appId); 

    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
        Utility.scrollToTop();
    }, [])

    const classes = useStyles()

    const [productList, setProductList] = useState([]);

    useEffect(()=> {

        if(props.productList && props.productList.length > 0){
            let tempList = [...props.productList];
            tempList.sort((a, b) => a.localeCompare(b))
            setProductList(tempList);
        } else{
            setProductList([]);
        }
    }, [props.productList]);

    return (

        <div className={classes.root}>
            <Grid container xs={8} className={classes.gridSeparation}>
                <h1 className={classes.successHeaderStyles}>
                    Congratulations! Your registration is complete.
                </h1>
            </Grid>
            <Grid container spacing={6} md={12} className={classes.gridSeparation}>
                <Grid item xs={12}>
                    <p className={classes.successBodyStyles}>
                        {successBody || 'Log in to see your account details.'}
                    </p>
                </Grid>
                <Grid item xs={12} md={props.isAwaitingApiCall ? 6 : 3} className={classes.buttonContainer}>
                    <Button
                        disabled={props.isAwaitingApiCall}
                        saving={props.isAwaitingApiCall}
                        onClick={() => props.postCall()}
                        name="GoToApplicationButton"
                        id="GoToApplicationButton">
                            {successCTA || 'My account'}
                    </Button>
                </Grid>
                {
                    productList.length > 0 &&
                    <Grid spacing={6} item xs={6} md={4} className={classes.userNotesSeparation}>
                            <span className={classes.userNotesStyles}>
                                <p>Remember, your User ID signs you in for:
                                    <ul>
                                        {
                                            productList.map(item=> <li>{item}</li> )
                                        }
                                    </ul>
                                </p>
                            </span>
                    </Grid>
                }
            </Grid>
        </div>
    )
}

export default SuccessPage
