import React, { useEffect } from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@manulife/mux';
import { equalsIgnoreCase } from '../../utils';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    loginHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0
    },
    loginBodyStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0
    },
    buttonTextStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF"
    }
}))


const LoginPage = props => {
    const classes = useStyles()

    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
    }, [])

    const redirectToLogin = appId => { 
        if (appId === 2320147) {
            window.location.href = "https://digital.customer.johnhancock.com";
        }
        else if (appId === 266976) {
            window.location.href = "https://ltc.customer.johnhancock.com";
        }
        else
            window.location.href = "https://customer.johnhancock.com";
    }

    return (
        <div className={classes.root}>
            <Grid container xs={8} className={classes.gridSeparation}>
                <h1 className={classes.loginHeaderStyles}>
                    It appears we are experiencing technical difficulties at this time. Please try to log in again later.
                </h1>
            </Grid>
            <Grid container spacing={6} md={8} className={classes.gridSeparation}>
                <Grid item xs={12}>
                    <p className={classes.loginBodyStyles}>
                        Please sign in using your ID associated with your account.
                    </p>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        customStyle={{
                            buttonStyle: {
                                width: "148px",
                                height: "60px"
                            }
                        }}
                        name="RedirectToLoginButton"
                        id="RedirectToLoginButton"
                        onClick={e => redirectToLogin(props.formValues.appId)}><div className={classes.buttonTextStyles}>Sign In</div></Button> 
                </Grid>
            </Grid>
        </div>
    )
}

export default LoginPage
