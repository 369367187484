import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import {
    Card,
    CardContent,
    CardHeader,
    Icon
} from '@manulife/mux';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    subheaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    registerText: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "16px",
        fontStyle: "normal",
        marginLeft: "5px",
        textAlign: "left",
        color: "#282B3E",
        marginTop: "-180px"
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px",
    },
    gridSeparation: {
        paddingBottom: "40px"
    },
    gridMargin: {
        paddingBottom: "20px",
        marginRight: "40px"
    },
    cardAdornment: {
        height: "14px",
        width: "399px",
        background: "#00009A",
        marginLeft: "-20px",
        marginTop: "-20px"
    },
    gridSpacing: {
        paddingBottom: "20px"
    },
    linkStyles: {
        textDecoration: "none",
        color: "#282B3E",
        verticalAlign:"-2px"
    }

}));

const ownerUrl = process.env.LIFE_POLICY_OWNER ? `${process.env.LIFE_POLICY_OWNER}` : "https://centralregistry.jhapps-tst.aks.manulife.com/?appName=MyDashboard"
const hipaUrl = process.env.LTC_LEGAL_REPRESENTATIVE ? `${process.env.LTC_LEGAL_REPRESENTATIVE}` : "https://test.register.jhancock.com/Registration-WAR/registerNow?registrationEntryPoint=13&appName=LTCPortal"

const LTCInterceptPage = props => {

    const classes = useStyles();

    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
    }, [])


    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.gridSeparation}>
                <h1 className={classes.headerText}>Registration</h1>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridSeparation}>
                <span className={classes.subheaderStyles}>Please select what your role is with the John Hancock product you are registering.</span>
            </Grid>
            <Grid item xs={12} className={classes.gridSeparation}>
                <span className={classes.subheaderStyles}>I am the:</span>
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridMargin}>
                <Card customStyle={{ cardStyle: { height: '238px', width: '399px' } }}>
                    <CardHeader>
                        <Grid item className={classes.cardAdornment}>

                        </Grid>
                        <Grid item className={classes.gridSpacing}>

                        </Grid>
                        Insured
                    </CardHeader>
                    <CardContent customStyle={{ contentStyle: { marginTop: '120px' } }}>
                        
                        <Icon color="none"
                            fill="#ffffff"
                            manulifeName="chevronright_withcircle_filled"
                            secondaryColor="#FF7769"
                        /><span className={classes.registerText}><a className={classes.linkStyles} href={ownerUrl}> Register </a></span>
                        
                    </CardContent>

                </Card>
            </Grid>
            <Grid item xs={12} md={4} className={classes.gridMargin}>
                <Card customStyle={{ cardStyle: { height: '238px', width: '399px' } }}>
                    <CardHeader>
                        <Grid item className={classes.cardAdornment}>

                        </Grid>
                        <Grid item className={classes.gridSpacing}>

                        </Grid>
                        Legal representative/HIPAA authorized
                    </CardHeader>
                    <CardContent customStyle={{ contentStyle: { marginTop: '90px' } }}>

                        <Icon color="none"
                            fill="#ffffff"
                            manulifeName="chevronright_withcircle_filled"
                            secondaryColor="#FF7769"
                        /> <span className={classes.registerText}> <a className={classes.linkStyles} href={hipaUrl}>Register</a> </span>

                    </CardContent>

                </Card>
            </Grid>
            
        </Grid>
    );
};

export default LTCInterceptPage;