import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import useStyles from "./Style";
import {Button, Progress} from '@manulife/mux';

const AnnuitiesPage = props => {

    const classes = useStyles()
    
    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
    }, [])

    function goToAnnuities(){
        const REACT_APP_ANNUTIES_URL = process.env.REACT_APP_ANNUTIES_URL ? process.env.REACT_APP_ANNUTIES_URL : "https://stage.jhannuities.com/annlogin/login.jsp";
        if(REACT_APP_ANNUTIES_URL.startsWith("https://test.jhannuities.com") || 
            REACT_APP_ANNUTIES_URL.startsWith("https://stage.jhannuities.com") || 
            REACT_APP_ANNUTIES_URL.startsWith("https://www.jhannuities.com")){
            window.open(REACT_APP_ANNUTIES_URL, "_blank")
        }
    }

    return (
        <div className={classes.root}>
            <Grid container xs={12}>
                <h1 className={classes.headerStyle}>
                    Keep your online account safe
                </h1>
            </Grid>
            <Grid container xs={12}>
                <h3 className={classes.subHeaderStyle}>
                    Before you can finish registering, we need to update the security on your existing account.
                </h3>
            </Grid>
            <Grid container xs={12}>
                <span className={classes.bodyContentStyle}>
                    Please keep this window open as you complete the following steps:
                </span>
            </Grid>
            
            <Grid container className={classes.gridSeparation}>
                <Grid container className={classes.itemRootContainer}>
                    <span className={classes.stepTextStyle}>STEP 1</span>
                    <Grid className={classes.itemContainer} item xs={6}>
                        <span className={classes.stepContentStyle}>View your annuities account and update your security settings in the new window</span>
                        <div>
                            <Button
                                customStyle={{
                                    buttonStyle: {
                                        width: "300px",
                                        height: "60px",
                                        marginTop: "15px"
                                    }
                                }}
                                name="BackToLoginButton"
                                id="BackToLoginButton"
                                onClick={e => goToAnnuities()}><div className={classes.buttonTextStyles}>View your annuities account</div></Button> 
                        </div>
                    </Grid>
                </Grid>

                <Grid container className={classes.itemRootContainer}>
                    <span className={classes.stepTextStyle}>STEP 2</span>
                    <Grid className={classes.itemContainer} item xs={6}>
                        <span className={classes.stepContentStyle}>Come back to this screen and start the registration process again.</span>
                        <div>
                            <Button
                                customStyle={{
                                    buttonStyle: {
                                        width: "148px",
                                        height: "60px",
                                        marginTop: "15px"
                                    }
                                }}
                                name="BackToLoginButton"
                                id="BackToLoginButton"
                                onClick={e => props.backFromAnnuities(props.from)}><div className={classes.buttonTextStyles}>Register</div></Button> 
                        </div>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
};

export default AnnuitiesPage;