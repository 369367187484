import axios from 'axios';

const ajaxEmail = (email, firstName, lastName, setErrorState, setShowSignIn, errorState, setSystemErrorCount, systemErrorCount, setEmailAjaxCallFail, onEmailAjaxCallResponse) => {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true
    };
    axios.post(
        process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/validateEmail` : 'http://localhost:8080/validateEmail',
        {
            "Email": email,
            "FirstName": firstName,
            "LastName": lastName
        },
        { headers }).then(response => {
            onEmailAjaxCallResponse(response, null);
            if (response.data.code === "200") {
                if (errorState.emailAddress.errMsg === "Email address is already taken") {
                    setErrorState({ ...errorState, emailAddress: { id: 'emailAddress', errMsg: '', isError: false } })
                }
                if(errorState.emailAddress.errMsg === "Encountered Error") {
                    setErrorState({ ...errorState, emailAddress: { id: 'emailAddress', errMsg: '', isError: false } })
                }
                setEmailAjaxCallFail(false);
                setShowSignIn(false)
            } else if (response.data.code === "9001" || response.data.code === "9014") {
                setErrorState({ ...errorState, emailAddress: { id: 'emailAddress', errMsg: 'Encountered Error', isError: true } })
                setEmailAjaxCallFail(true);
                setSystemErrorCount(systemErrorCount + 1);
            } else {
                if(email !== "") {
                    setErrorState({ ...errorState, emailAddress: { id: 'emailAddress', errMsg: 'Email address is already taken', isError: true } })
                    setShowSignIn(true)
                    setEmailAjaxCallFail(false);
                }
            }
        }).catch(error => {
            console.log("Error ========>", error);
            onEmailAjaxCallResponse(null, error);
        })

}

export default ajaxEmail;
