import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import { MyContext } from '../../Context/MyContext';
import { makeStyles } from "@material-ui/core/styles";
import { Info1 } from '@manulife/mux-cds-icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    mynotificationRoot: {
        backgroundColor: "white",
        width: "100%",
        boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        position: "relative",
    },
    notificationIconContainer: {
        display: "flex",
        backgroundColor: "#EDEDED",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        minHeight: "auto"
    },
    notificationBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0 20px 0 20px",
    },
    notificationRightContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },    
    messageTitle: {
        fontFamily: "Manulife JH Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#282B3E",
    },    
    messageDescription: {
        fontFamily: "Manulife JH Sans",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color:" #282B3E",
    },    
    crossContainer: {
        margin: "15px 15px 15px 0",
        padding: "5px 5px 5px 5px",
        cursor: "pointer",
        minWidth: "18px",
    }
}));
const MyNotification = () => {

    const classes = useStyles();

    const rootRef = useRef(null);
    const context = useContext(MyContext);
    let [notificationHeight, setNotificationHeght] = useState(64);

    useLayoutEffect(()=> {
        setNotificationHeght(rootRef.current ? rootRef.current.offsetHeight : 64);
    }, [context.notification.isVisible]);

    return (
        <MyContext.Consumer>
             {({notification, setNotification}) => {
                return notification.isVisible ? (
                    <div ref={rootRef} id="notification-message" className={classes.mynotificationRoot}>
                        <div style={{height: notificationHeight}} className={classes.notificationIconContainer}>
                            <Info1 color="#282B3E" style={{ width: "20px", height: "20px", background: "none", borderStyle: "none", alignItems: "center"}} />
                        </div>
                        <div className={classes.notificationRightContainer}>
                            <div className={classes.notificationBody}>
                                {
                                    notification.title &&
                                    <span className={classes.messageTitle}>{notification.title}</span>
                                }
                                <span className={classes.messageDescription}>
                                    {
                                        notification.message1 && 
                                        <strong>{notification.message1}</strong>
                                    }
                                    {notification.message2}
                                </span>
                            </div>
                        </div>
                    </div>
                 ) : <React.Fragment/>
             }}
        </MyContext.Consumer>
    );
}

export default MyNotification;
