import { makeStyles } from "@material-ui/core/styles";
const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    headerStyle: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0,
        paddingBottom: 25
    },
    subHeaderStyle: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E",
        margin: 0,
        paddingBottom: "40px"
    },
    bodyContentStyle: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "30px",
        fontStyle: "normal",
        color: "#000000",
        margin: 0,
        marginBottom: "30px",
    },
    buttonTextStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF"
    },
    itemRootContainer:{
        marginBottom: "40px",
    },
    stepTextStyle: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "30px",
        fontStyle: "normal",
        color: "#000000"
    },
    itemContainer: {
        marginLeft: "30px",
    },
    stepContentStyle: {
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "30px",
        fontStyle: "normal",
        color: "#000000"
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom:"20px",
        display:"inline-block",
        marginLeft:"8px"
    },
    inlineDisplay: {
        display: "flex",
        height:"100%",
        marginLeft:"8px"
    },
    inlineDisplay2:{
        display: "inline-block",
        marginTop: "10px",
        marginBottom:"10px",
    },
    userNotesHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    userNotesStyles: {
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    userNotesSeparation: {
        marginTop: "16px",
        border: "1px solid #EDEDED",
        boxSizing: "border-box"
    }
}));

export default useStyles;