import axios from 'axios';
import JsonConfig from '../utils/jsonConfig.json';
import { getQueryParams } from '../utils';
  
let { appId, jwt } = getQueryParams();

function registerApi(state, setPage, setErrorScenario, systemErrorCount, setSystemErrorCount, guid, setLoginHook, setIsAwaitingApiCall, errorState, setErrorState, setAlreadyRegistered, setpasswordUpdateRequired, setDtcRegisterError, setErrorType, setGuid, setModalOpen, setReview, isPhoneNumberPresent, setAuthenticationFlow) {
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true
  };
  setIsAwaitingApiCall(true)
  axios.post(
    process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/register` : "http://localhost:8080/register",
    {
      "phonenumber": !isPhoneNumberPresent ? state.phoneNumber.replace(/\D/g, '') : null,
      "password": state.password,
      "username": state.userName,
      "requestid": guid
    },
    { headers }).then(response => {
      setIsAwaitingApiCall(false)

      let errorScenarioWithoutReviewCodes = ["9009", "9011", "9027", "9036", "9037", "9038", "9041", "9048"]
      let dtdRegisterErrorCodes = ["9050", "9028", "9040"];
      let systemErrorCodes = ["9001", "9002", "9003", "9004", "9005", "9006", "9031", "9032", "9033", "9034", "9007", "9008", "9012", "9013", "9014", "9015", "9019", "9020", "9052"]
      let responseCode = response.data.code;
      if (responseCode === "2001") { // Successfully saved to cache
        setGuid(response.data.details.requestid)
        setPage(2)
      } else if (errorScenarioWithoutReviewCodes.includes(responseCode)){
        setReview(false);
        setErrorScenario(true);
      } else if (responseCode === "9010"){
        setReview(true);
        setErrorScenario(true);
      } else if (responseCode === "9035") {
        setAlreadyRegistered(true)
      } else if (responseCode === "3000") {
        setAuthenticationFlow(true);
        setPage(4);
        setGuid(response.data.details.requestid)
      } else if (responseCode === "9045") {
        setpasswordUpdateRequired(true)
      } else if (dtdRegisterErrorCodes.includes(responseCode)) {
        setDtcRegisterError(true)
      } else if(responseCode === "9031") {        
        setSystemErrorCount(0);
        setErrorState({ ...errorState, userName: { id: 'userName', errMsg: 'Try again user ID exists', isError: true } })
        setPage(1)
      } else if(responseCode === "9032") {
        if((appId === JsonConfig.app_id.MultiLife || appId === JsonConfig.app_id.VITALITY) && jwt){
          setErrorScenario(true);
        } else{
          setSystemErrorCount(0);
          setErrorState({ ...errorState, emailAddress: { id: 'emailAddress', errMsg: 'Try again email exists', isError: true } })
          setPage(0);
        }
      } else if (responseCode === "9018") {
        setLoginHook(true)
      } else if (systemErrorCodes.includes(responseCode)) {
        setSystemErrorCount(systemErrorCount + 1)
        setModalOpen(false)
      } else{
        setSystemErrorCount(systemErrorCount + 1)
        setModalOpen(false)
      }
    }).catch(error => {
      console.log("Error ========>", error);
      setIsAwaitingApiCall(false)
      if (error.message === "Network Error") {
        setErrorType("Technical")
      } else{
        setSystemErrorCount(systemErrorCount + 1)
        setModalOpen(false)
      }
    })
}

export default registerApi;
