import axios from 'axios';

function getIpAddress() {
    
    return new Promise((resolve, reject)=> {
        axios.get(
            "https://api.ipify.org/?format=json")
            .then(response => {
                if(response.status === 200) return response.data;
            })
            .then(response => {
                resolve(response.ip);
            }).catch(error => {
                resolve("");
            })
    });
}

export default getIpAddress;

