import React from 'react';
import JsonConfig from '../../../utils/jsonConfig.json';
import { getQueryParams } from '../../../utils';
import { HomeIc } from '../../../utils/Images';

let { appId } = getQueryParams();


const LeftNavigationPanel = () => {

    return (
        
        <div class="left-navigation-panel">
            <a href={JsonConfig.static_redirect_url[appId] ? JsonConfig.static_redirect_url[appId] : JsonConfig.static_redirect_url.default} class="home-menu-item">
                <img src={HomeIc} alt="home menu" style={{width: "24px"}}/>
                <span class='menu-item-text'>Home</span>
            </a>
        </div>
    )
}

export default LeftNavigationPanel;
