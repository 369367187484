import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import { Progress } from '@manulife/mux';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "#ffffff77",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const Loader = (props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item>
                <Progress
                    isFloating={false}
                    customStyle={{
                        spinnerStyle: {
                            position: "relative",
                            height: "40px",
                            width: "40px"
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default Loader;
