import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getQueryParams } from '../../utils';
import { Grid } from "@material-ui/core";
import { Button } from '@manulife/mux';
import JsonConfig from '../../utils/jsonConfig.json';
import {unloadChatBot} from '../../scripts/ChatBotScript';
import Utility from '../../utils/utility';


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    errorHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    textLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "22px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    contactLabelStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "26px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    closeLabelStyles: {
        fontWeight: "normal",
        fontFamily: font,
        fontSize: "13px",
        lineHeight: "18px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    buttonPadding: {
        paddingTop: "25px",
        paddingBottom: "25px"
    },
    buttonContainer: {
        "& button": {
            minWidth: "300px",
            width: "300px",
            height: "70px",            
            fontWeight: "500",
            fontFamily: font,
            fontSize: "18px",
            lineHeight: "22px",
            fontStyle: "normal",
            textAlign: "center",
            color: "#FFFFFF",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    contactSeparation: {
        paddingBottom: "20px"
    }
}));

let { appId } = getQueryParams(); 

const SessionExpiredPage = props => {
    const classes = useStyles();

    useEffect(()=> {
        if(window.jhwebchat){
            unloadChatBot();
        }
        props.setSessionTimeoutEnable(false)
        Utility.scrollToTop();
    }, [])

    const redirectToYourInformation = () => {
            window.location.reload();
    };
    
    let supportNumber = JsonConfig.call_center_number[appId] ? JsonConfig.call_center_number[appId] : JsonConfig.call_center_number.default;
    let startTime = JsonConfig.time[appId] ? JsonConfig.time[appId].start_time : JsonConfig.time.default.start_time;
    let endTime = JsonConfig.time[appId] ? JsonConfig.time[appId].end_time : JsonConfig.time.default.end_time;;
    let validatePhoneNumberRegex = /^[0-9\-/?]*$/;

    return (
        <div className={classes.root}>
            <Grid container className={classes.gridSeparation}>
                <Grid item md={8} xs={12}>
                    <span className={classes.errorHeaderStyles}>Your session has expired</span>
                </Grid>
            </Grid>
            <Grid container className={classes.gridSeparation}>
                <Grid item md={8} xs={12}>
                    <span className={classes.subheaderStyles}>For your security, we haven't saved any of your personal data. Please try registering again.</span>
                </Grid>
            </Grid>
            <Grid container className={classes.buttonPadding}>
                <Grid item md={8} xs={12} className={classes.buttonContainer}>
                    <Button
                        onClick={e => redirectToYourInformation()}>Back to your information</Button>
                </Grid>
            </Grid>
            <Grid container className={classes.gridSeparation}>
                <Grid item md={8} xs={12}>
                    <span className={classes.subheaderStyles}>If you need immediate assistance, please call us at:</span>
                </Grid>
            </Grid>
            <Grid container className={classes.contactSeparation}>
                <Grid item md={8} xs={12}>
                    <span className={classes.textLabelStyles}>Customer Center</span>
                </Grid>
                <Grid item md={8} xs={12}>
                    <a href={`tel:${validatePhoneNumberRegex.test(supportNumber) ? supportNumber : ""}`} className={classes.contactLabelStyles}><u>{supportNumber}</u></a>
                </Grid>
            </Grid>
            <Grid container className={classes.contactSeparation}>
                <Grid item md={8} xs={12}>
                    <span className={classes.textLabelStyles}>Monday - Friday, {startTime} - {endTime} ET</span>
                </Grid>
                <Grid item xs={12}>
                    <Grid item md={8} xs={12}>
                        <span className={classes.closeLabelStyles}>Closed on Saturday, Sunday and public holidays</span>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SessionExpiredPage;