import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Button } from '@manulife/mux';
import { Progress } from '@manulife/mux';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    gridSeparation: {
        paddingBottom: "25px"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    errorHeaderStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    buttonPadding: {
        paddingTop: "25px",
        paddingBottom: "25px"
    },
    buttonContainer: {
        "& button": {
            minWidth: "230px",
            width: "250px",
            height: "60px",
            fontWeight: "500",
            fontFamily: font,
            fontSize: "18px",
            lineHeight: "22px",
            fontStyle: "normal",
            textAlign: "center",
            color: "#FFFFFF",

            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    gridBottomSpacing: {
        paddingBottom: "80px"
    },
    inlineDisplay: {
        display: "flex",
        height:"100%",
        marginLeft:"8px"
    },
    inlineDisplay2:{
        display: "inline-block",
        marginTop: "10px",
        marginBottom:"10px",
    },
    loadingText: {
        fontFamily: "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif",
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom:"20px",
        display:"inline-block",
        marginLeft:"8px"

    },
    flexDisplay:{
        display:"flex"
    }

}));



const AccountUnlockPage = props => {
    const classes = useStyles();

    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
    }, [])
    
    return (
        <div className={classes.root}>
            <Grid container md={8} xs={12} className={classes.gridSeparation}>
                <span className={classes.errorHeaderStyles}>Need help?</span>
            </Grid>
            <Grid container md={8} xs={12} className={classes.gridSeparation}>
                <span className={classes.subheaderStyles}>Click the button below to reset your password.</span>
            </Grid>
            <Grid container xs={12} className={classes.buttonPadding}>
                <Grid item md={8} xs={12} className={classes.buttonContainer}>
                    <Button
                        saving={props.isAwaitingApiCall}
                        disabled={props.isAwaitingApiCall}
                        name="UnlockAccountButton"
                        id="UnlockAccountButton"
                        onClick={e => props.unlockAccount()}>Unlock your account</Button>
                </Grid>
            </Grid>
            <Grid container md={8} xs={12} className={classes.gridBottomSpacing}>

            </Grid>
        </div>
    );
};



export default AccountUnlockPage