import React from 'react';
import PageBody from './PageBody'


function PageBodyWrapper() {
  
  return <div data-testid="PageBody-root">
          <PageBody page={0} />
        </div>
}

export default PageBodyWrapper;
