import axios from 'axios';
import Utility from '../utils/utility';

function generateMfa(state, setPage, systemErrorCount, setSystemErrorCount, guid,setErrorScenario,setIsAwaitingApiCall, setMfaAttemptMaxed, setErrorType, setModalOpen, context) {
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': true
  };
  setIsAwaitingApiCall(true)
  axios.post(
    process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/generateMFA`: "http://localhost:8080/generateMFA",
    {
      "PhoneNumber": state.phoneNumber, ///ADD REAL PHONE NUMBER
      "TYPE": state.mfaType,
      "GUID": guid
    },
    { headers }).then(response => {
      setIsAwaitingApiCall(false)
      setModalOpen(false)
      if(response.data.code === "200"){
        setSystemErrorCount(0);
        setPage(3)
        if(context){
          context.setNotification({...context.notification, 
              isVisible: true,
              title: "",
              message1: "One time code re-sent to "+Utility.getPhoneNumberLabel(state.phoneNumber)+". ",
              message2: "Please use the most recent code.",
              type: "info"});
        }
      } else if (response.data.code === "9025"){
        setErrorScenario(true)
      } else if (response.data.code === "9033") {
        setMfaAttemptMaxed(true)
      } else if (response.data.code === "9022" || response.data.code === "2002" || response.data.code === "9052") {
        setSystemErrorCount(systemErrorCount + 1)
      } else{
        setSystemErrorCount(systemErrorCount + 1);
      }

    }).catch(error => {
      setIsAwaitingApiCall(false)
      console.log("Error ========>", error);
      if (error.message === "Network Error") {
        setErrorType("Technical")
      } else{
        setSystemErrorCount(systemErrorCount + 1)
      }
    })
}

export default generateMfa;
