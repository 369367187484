import Utility from "./utility";

export const getQueryParams = () => {
  const search = new URLSearchParams(window.location.search);
  let appId = Utility.sanitize(search.get('appId'));
  let appName = Utility.sanitize(search.get('appName'));
  let jwt = Utility.sanitize(search.get('jwt'));

  return {
    appId: appId,
    appName: appName,
    jwt: jwt,
  };
};