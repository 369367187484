import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import { Icon } from '@manulife/mux';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "40px"
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px"
    },
    subText: {
        fontFamily: font,
        fontSize: "22px",
        color: "#282B3E",
        fontWeight: "300",
        lineHeight: "34px"
    },
    tableStyles: {
        width: "50%",
        fontFamily: font,
        fontSize: "16px",
        color: "#282B3E",
        fontWeight: "500",
        lineHeight: "26px"
    },
    urlDecoration: {
        fontFamily: font,
        fontSize: "16px",
        color: "#282B3E",
        textDecoration: "none",
        fontWeight: "500",
        textDecorationColor: "#0000C1",
        lineHeight: "20px"
    }
}))

const lifeUrl = process.env.LIFE_INSURANCE_URL ? `${process.env.LIFE_INSURANCE_URL}` : "https://portalsit-jhinsurance.cs68.force.com/jhlifecustomer"
const ltcUrl = process.env.LTC_URL ? `${process.env.LTC_URL}` : "https://jhltc--portalsit5.sandbox.my.site.com/customer/s/"
const dtcIraUrl = process.env.DTC_IRA_URL ? `${process.env.DTC_IRA_URL}` : "https://jhapplications-usc.test.aks.manulife.com"
const vitalityUrl = process.env.REACT_APP_VITALITY_URL ? `${process.env.REACT_APP_VITALITY_URL}` : "https://test.johnhancockvitality.com/login"

const UpdatePasswordPage = props => {

    const classes = useStyles();

    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
    }, [])

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.gridSeparation}>
                <h1 className={classes.headerText}>Password update required</h1>
            </Grid>
            <Grid item xs={12} className={classes.gridSeparation}>
                <span className={classes.subText}>Before you can register, please reset your existing account password.</span>
            </Grid>
            <Grid item xs={12} className={classes.gridSeparation}>
                <table className={classes.tableStyles}>
                    <tr>
                        <td>STEP 1</td>
                        <td>Log in to your existing account</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><Icon
                            color="#1E212F"
                            fill="#ffffff"
                            manulifeName="chevronright_withcircle_filled"
                            secondaryColor="#FF7769" /> &nbsp;<a className={classes.urlDecoration} href={lifeUrl}>Life Insurance</a></td>
                        <td><Icon
                            color="#1E212F"
                            fill="#ffffff"
                            manulifeName="chevronright_withcircle_filled"
                            secondaryColor="#FF7769" /> &nbsp;<a className={classes.urlDecoration} href={ltcUrl}>Long-Term Care</a></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><Icon
                            color="#1E212F"
                            fill="#ffffff"
                            manulifeName="chevronright_withcircle_filled"
                            secondaryColor="#FF7769" /> &nbsp;<a className={classes.urlDecoration} href={dtcIraUrl}>Mutual Fund IRA/Roth IRA</a></td>
                        <td><Icon
                            color="#1E212F"
                            fill="#ffffff"
                            manulifeName="chevronright_withcircle_filled"
                            secondaryColor="#FF7769" /> &nbsp;<a className={classes.urlDecoration} href={vitalityUrl}>Vitality account</a></td>
                    </tr>
                    <tr>
                        <td>STEP 2</td>
                        <td>Reset your password</td>
                    </tr>
                    <tr>
                        <td>STEP 3</td>
                        <td>Restart the registration process</td>
                    </tr>
                </table>
            </Grid>
        </Grid>
    );
};

export default UpdatePasswordPage;