import axios from 'axios';
import { getQueryParams } from '../utils';

 const resendMultilifeInvite = (onResponse) => {
    
    let { appId, jwt } = getQueryParams();
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true
    };
    axios.post(
        process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/resendlink` : "http://localhost:8080/resendlink",
        {
            "appid": appId,
            "token": jwt
        },
        { headers }).then(response => {
            onResponse(response.data);
        }).catch(error => {
            console.log("Error ========>", error);
            let errorTemp = {
                code: "9999",
                message: "Exception"
            }
            onResponse(errorTemp);
        });
};

export default resendMultilifeInvite;
