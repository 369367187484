import React, { useEffect } from 'react'
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";

const useStyles = makeStyles((theme) => ({

    successInviteRoot: {
        width: "100%",
    },
    successInviteGridSeparation: {
        paddingBottom: "25px"
    },
    successInviteText: {
        fontFamily: font,
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "44px",
        lineHeight: "58px",
        color: "#202336",
        marginBottom: "36px",
    }
}));

const SuccessInvitePage = (props) => {
    
    const classes = useStyles();
    
    useEffect(()=> {
        props.setSessionTimeoutEnable(false)
    }, [])

    return (
        <div data-testid="success-invite-root" className={classes.successInviteRoot}>
            <Grid item md={12} xs={12} className={classes.successInviteGridSeparation}>
                <div className={classes.successInviteText}>A new registration link has been sent to the email address we have on file.</div>
            </Grid>         
        </div>
    );
}

export default SuccessInvitePage;
