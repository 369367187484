import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Grid } from '@material-ui/core'
import { TextInput, INPUT_VARIANT, Button, BUTTON_VARIANT } from '@manulife/mux';
import { Progress } from '@manulife/mux';
import EncounteredError from '../../components/EncounteredError';
import Utility, { doNothing } from '../../utils/utility';
import { MyContext } from '../../Context/MyContext';
import useAdobeDataLayer from '../../hooks/useAdobeDataLayer';
import InputError from '../../components/InputError/InputError';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "40px"
    },
    errorStyle: {
        paddingTop: "0.5em",
        marginTop: "-25px",
        paddingBottom: "25px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif",
        fontSize: "18px",
        width: "100%",
        display: "block",
        color: "#202336"
    },
    textStyle: {
        color: "black"
    },
    buttonWrapper: {
        marginTop: "20px",
        "& button": {
            marginBottom: "1rem",
            [theme.breakpoints.up("sm")]: {
                marginBottom: 0,
                marginLeft: "1.5rem",
                "&:first-child": {
                    marginLeft: 0
                }
            },
            [theme.breakpoints.only("xs")]: {
                width: "100%"
            },
            "&:last-child": {
                color: "#282B3E",
            },
            "&:last-child:hover": {
                color: "#fff"
            }
        }        
    },
    errorLabelStyle: {
        color: "#A00E18 !important",
        fontWeight: "300px"
    },
    inlineDisplay: {
        [theme.breakpoints.only('xs')]: {
            justifyContent: "center",
        },
        [theme.breakpoints.only('md')]: {
            marginLeft: "82.5px"
        },
        [theme.breakpoints.only('lg')]: {
            marginLeft: "82.5px"
        },
        display: "flex",
        height: "100%",
        marginLeft: "8px",
    },
    inlineDisplay2: {
        display: "inline-block",
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif",
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: "inline-block",
        marginLeft: "8px",


    },
    flexDisplay: {
        display: "flex"
    }
}))

const MfaVerificationPage = props => {
    const adobeDataLayer = useAdobeDataLayer();

    const {setSessionTimeoutEnable, verify, systemErrorCount, isAwaitingApiCall, generateMfa} = props;

    const context = useContext(MyContext);


    let [mfaCodeError, setMfaCodeError] = useState({securityCode: { id: 'securityCode', errMsg: '', isError: false, warning: false }});
    let [mfaCode, setMfaCode] = useState("");
    let [isMfaValidationFailed, setMfaValidationFailed] = useState(false);
    let [isCallingNewCodeAPI, setCallingNewCodeAPI] = useState(false);


    useEffect(()=> {
        setSessionTimeoutEnable(true)
        Utility.scrollToTop();
    }, [])
    
    useEffect(()=> {
        // Temporary solution to show/hide loader in "Gent New Code" CTA button
        if(!props.isAwaitingApiCall && isCallingNewCodeAPI){
            setCallingNewCodeAPI(false);
        }
    }, [props.isAwaitingApiCall, isCallingNewCodeAPI])

    const classes = useStyles()
    const changeHandler = (value) => {        
        setMfaCode(value);
        setCodeError(!value, true);
    }

    function setCodeError(isEmpty, warning){
        let mfaCodeErrorTmp = { securityCode: { id: 'securityCode', errMsg: isEmpty ? 'Enter security code' : "", isError: isEmpty, warning }}
        setMfaCodeError(mfaCodeErrorTmp);
    }

    function verifySecurityCode(){
        if(validate()){
            setMfaValidationFailed(false);
            context.setNotification({...context.notification, isVisible: false, title: "", message1: "", message2: "", type: "info"})
            verify(mfaCode, setMfaCode, setMfaValidationFailed);
        }
    }

    function validate(){
        let securityCodeTemp = mfaCode?.trim();
        if(!securityCodeTemp){
            setCodeError(true, false)
            setMfaCode("");
            adobeDataLayer.registrationUnsuccessfull('mfa-error', '', 'Empty Code', 'step3')
            return false;
        } else{
            return true;
        }
    }

    return (
        <Grid container spacing={0}>
            {
                systemErrorCount > 0 &&
                    <EncounteredError systemErrorCount={systemErrorCount}/>
            }
            <Grid item md={9} xs={12} className={classes.gridSeparation}>
                <span className={classes.labelStyle} >
                    Please enter the code you received below:
                </span>
            </Grid>
            <Grid item md={5} xs={12} className={"gridSeparation1 myinput"+(mfaCodeError.securityCode.isError && !mfaCodeError.securityCode.warning ? " error" : "")}>
                <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                    placeholder="Enter your one time code"
                    value={mfaCode}
                    onKeyDown={doNothing}
                    onChange={e => changeHandler(e)}
                    onBlur={()=> setMfaCodeError({...mfaCodeError, securityCode: { ...mfaCodeError.securityCode, warning: false }})}
                    customStyle={{
                        rootStyle: {
                            width: "100%"
                        },
                        inputStyle: {
                            padding: '0px'
                        }
                    }} name="securityCode" />
                    {
                        mfaCodeError.securityCode.isError &&
                        <InputError errorState={mfaCodeError} inputId="securityCode" showIcon={true}/>
                    }
            </Grid>
            
            {
                isMfaValidationFailed &&
                    <Grid item md={12} xs={12} className={classes.gridSeparation}>
                        <span id="incorrect-code-error" className={classes.errorLabelStyle}>Sorry, there was an error with the code you entered. Please verify that the code is correct and click "Submit". The code is valid for 10 minutes. If your code has expired, please click "Get a new code" below to request a new one.
                        </span>
                    </Grid>
            }
            <Grid item xs={12} className={classes.buttonWrapper}>
                <Button
                    disabled={isAwaitingApiCall || isCallingNewCodeAPI}
                    onClick={() => verifySecurityCode()}
                    saving={isAwaitingApiCall && !isCallingNewCodeAPI}
                    name="MFAVerifyButton"
                    id="MFAVerifyButton"
                >Submit</Button>
                <Button
                    variant={BUTTON_VARIANT.SECONDARY}
                    name="NewCodeButton"
                    id="NewCodeButton"
                    disabled={isAwaitingApiCall || isCallingNewCodeAPI}
                    saving={isCallingNewCodeAPI}
                    onClick={() => {
                        setMfaValidationFailed(false);
                        setCallingNewCodeAPI(true);
                        generateMfa();
                        setMfaCode("");
                        setCodeError(false, false);
                    }}>Get a new code</Button>

                {/* {isAwaitingApiCall && <div className={classes.inlineDisplay}>
                    <div className={classes.inlineDisplay2}>

                        <Progress isFloating={false} customStyle={{
                            spinnerStyle: {
                                position: "relative",
                                height: "40px",
                                width: "40px"
                            }
                        }} />
                    </div>
                    <p className={classes.loadingText}>Please wait while we process your information</p>
                </div>
                } */}
            </Grid>
        </Grid>
    )
}

export default MfaVerificationPage