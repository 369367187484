import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Info1, Caution } from '@manulife/mux-cds-icons';
import { Grid } from '@material-ui/core';
import { Eye1, Eye2 } from '@manulife/mux-cds-icons';
import { TextInput, INPUT_VARIANT, Button, ActionButton } from '@manulife/mux';
import { Tooltip } from '@manulife/mux';
import { validate } from '../../components/PageBody/validate'
import { Progress } from '@manulife/mux';
import { MessageCard, MESSAGE_VARIANT } from '@manulife/mux';
import EncounteredError from '../../components/EncounteredError';
import { doNothing, scrollToTop } from '../../utils/utility';
import InputError from '../../components/InputError/InputError';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    subheaderStyles: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "40px"
    },
    buttonContainer:{
        paddingBottom: "40px",
        marginTop: "20px",
        "& button": {
            width: "150px",
            minWidth: "145px",
            height: "60px",            
            [theme.breakpoints.only("xs")]: {
                width: "100%"
            }
        }
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "500",
        fontFamily: font,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px"
    },
    subText: {
        fontFamily: font,
        fontSize: "22px",
        color: "#282B3E",
        lineHeight: "28px"
    },
    InLine: {
        display: displayStyle,
        width: "100%"
    },
    gridNoMargin: {
        margin: "0px",
        paddingBottom: "0px !important"
    },
    showIcon: {
        display: displayStyle,
        marginLeft: "-25px",
        marginTop: "-20px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "13px",
        width: "100%",
        display: "block",
        color: "#282B3E",
        lineHeight: "20px"
    },
    headerMargin: {
        marginTop: "49px"
    },
    buttonTextStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF"
    },
    signInUnderline: {
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "500",
        color: "#282B3E",
        textDecorationColor: "#0000C1",
        lineHeight: "28px",
        fontStyle: "normal"
    },
    inlinePadding: {
        paddingRight: "6em"
    },
    footnoteText: {
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "normal",
        color: "#282B3E",
        lineHeight: "24px",
        fontStyle: "normal"
    },
    errorPasswordLabel: {
        color: "rgb(219, 31, 0)",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
        margin: "-10px 0px 1.5em"
    },
    inlineDisplay: {
        display: "flex",
        height: "100%",
        marginLeft: "8px",
        width: "64%",
        float: "right"
    },
    inlineDisplay2: {
        display: displayStyle,
        marginTop: "10px",
        marginBottom: "10px",
    },
    loadingText: {
        fontFamily: font,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom: "20px",
        display: displayStyle,
        marginLeft: "8px"
    },
    linkStyles: {
        fontWeight: "500",
        color: "#282B3E",
        textDecorationColor: "#0000c1",
        textUnderlinePosition: "under"
    },
    flexDisplay: {
        display: "flex"
    }
}));

const AuthenticationPage = props => {

    const classes = useStyles();
    const tooltipText = "You will use this User ID as your log in for additional accounts you may have for Life Insurance, Long-Term Care, Vitality, and/or Mutual Fund IRA/Roth IRA.";
    const titleText = "Incorrect User ID / password combination"
    const errorText = "Please note that your account will be locked after too many failed login attempts."

    const [allValues, setAllValues] = useState({
        passwordVisible: false
    });
    
    useEffect(()=> {
        props.setSessionTimeoutEnable(true)
        scrollToTop();
    }, [])

    const handleShow = (name) => {
        setAllValues({ ...allValues, [name]: !allValues[name] })
    }

    const changeHandler = (name, value) => {
        const newFormValues = { ...props.formValues, [name]: value };
        props.setFormValues(newFormValues)
        props.setErrorState(validate(newFormValues, name, props.errorState, false, 4, null, true));
    }

    return (
        <Grid container spacing={0}>
            {
                props.systemErrorCount > 0 &&
                    <EncounteredError systemErrorCount={props.systemErrorCount}/>
            }
            {
                ((props.authErrorCount > 0 || props.isamPasswordError)) &&
                <Grid id="authentication-error" item xs={12} md={9} className={classes.gridSeparation}>
                    <MessageCard icon={<Caution color="#1E212F" fontsize="30px" style={{ verticalAlign: '-webkit-baseline-middle' }} />} variant={MESSAGE_VARIANT.PRIMARY_WARNING} titleText={titleText}>
                        {errorText}
                    </MessageCard>
                </Grid>
            }

            <Grid item xs={12} md={9} className={[classes.flexDisplay, classes.gridSeparation]}>
                <span className={classes.subheaderStyles}>The User ID you create is your login for all accounts.</span>
                <Tooltip title={tooltipText} bubbleWidth={200}
                    customStyle={{
                        rootStyle: {
                            paddingLeft: "5px"
                        }
                    }}
                ><Info1 color="#282B3E"/>
                </Tooltip>
            </Grid>
            <Grid item md={9} xs={12} className={"gridSeparation1 myinput"+(props.errorState.authUserId.isError && !props.errorState.authUserId.warning ? " error" : "")}>
                <label className={classes.labelStyle} >
                    User ID
                </label>
                <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                    id="authUserId"
                    value={props.formValues.authUserId}
                    onKeyDown={doNothing}
                    onChange={e => changeHandler("authUserId", e)}
                    onBlur={()=> props.setErrorState({...props.errorState, authUserId: { ...props.errorState.authUserId, warning: false }})}
                    required={true}
                    placeholder="Enter your User ID"
                    customStyle={{
                        rootStyle: {
                            width: "100%",

                        },
                        inputStyle: {
                            padding: '0px'
                        }
                    }}
                    name="authUserId" />

                    {
                        props.errorState.authUserId.isError && 
                        <InputError errorState={props.errorState} inputId="authUserId" showIcon={true}/>
                    }
            </Grid>
            <Grid item md={9} xs={12} className={"gridSeparation1 myinput"+(props.errorState.authPassword.isError && !props.errorState.authPassword.warning ? " error" : "")}>
                <label className={classes.labelStyle} >
                    Password
                </label>
                <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                    id="authPassword"
                    type={allValues.passwordVisible ? "text" : "password"}
                    required={true}
                    placeholder="Enter your password"
                    value={props.formValues.authPassword}
                    onKeyDown={doNothing}
                    className={classes.InLine}
                    onChange={e => changeHandler("authPassword", e)}
                    onBlur={()=> props.setErrorState({...props.errorState, authPassword: { ...props.errorState.authPassword, warning: false }})}
                    customStyle={{
                        rootStyle: {
                            width: "100%"

                        },
                        inputStyle: {
                            padding: '0px'
                        }
                    }}
                    name="authPassword" 
                    isIconClickable={true}
                    icon={<ActionButton
                            ariaLabel="Edit"
                            icon={allValues.passwordVisible ? <Eye2 color="#282B3E" />
                                : <Eye1 color="#282B3E" />
                            }
                            onClick={e => handleShow("passwordVisible")}
                        />}
                    />            
                {
                    props.errorState.authPassword.isError && 
                        <InputError errorState={props.errorState} inputId="authPassword" showIcon={true}/>
                }

            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                    name="AuthenticateButton"
                    id="AuthenticateButton"
                    onClick={e => props.onClick(4)}
                    disabled={props.isAwaitingApiCall}
                    saving={props.isAwaitingApiCall}>Log In</Button>
                {/* {props.isAwaitingApiCall && <div className={classes.inlineDisplay}>
                    <div className={classes.inlineDisplay2}>
                        <Progress isFloating={false} customStyle={{
                            spinnerStyle: {
                                position: "relative",
                                height: "40px",
                                width: "40px"
                            }
                        }} />
                    </div>
                    <p className={classes.loadingText}>Please wait while we process your information</p>
                </div>} */}
            </Grid>
            <Grid item md={9} xs={12}>
                <span className={classes.footnoteText}><b>Please note:</b> If you have forgotten your account or password, you can recover them via 'Sign in' on <a className={classes.linkStyles} rel="noopener noreferrer" target="_blank" href="https://johnhancock.com">JohnHancock.com</a></span>
            </Grid>
        </Grid>
    );
};

export default AuthenticationPage;