import axios from 'axios';

const unlockAccount = (appid, setIsAwaitingApiCall, setErrorType, unlockToken) => {

    const accountUnlockUrl = process.env.REACT_APP_UNLOCK_ACCOUNT_URL ? `${process.env.REACT_APP_UNLOCK_ACCOUNT_URL}` : "https://stage.partnerlink.jhancock.com/cusc/accountunlock/"

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true
    };
    setIsAwaitingApiCall(true)
    axios.post(
        process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/getBusinessUnit` : "http://localhost:8080/getBusinessUnit",
        {
            "appid": appid
        },
        { headers }).then(response => {
            console.log(response.data.message)
            if(response.data.code === "2000") {
                const bu = response.data.details.bu;
                console.log("BU ..." + bu);
                let redirectUrl = accountUnlockUrl + bu + "?token=" + unlockToken;
                console.log("redirectUrl ..." + redirectUrl);
                window.location.replace(redirectUrl);
            }
            
        }).catch(error => {
            setIsAwaitingApiCall(false)
            console.log("Error ========>", error);
            if (error.message === "Network Error") {
                setErrorType("Technical")
            }
        })
};

export default unlockAccount;