import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Eye1, Eye2 } from '@manulife/mux-cds-icons';
import { TextInput, INPUT_VARIANT, ActionButton } from '@manulife/mux';
import { doNothing } from '../../utils/utility';

const font = "\"Manulife JH Sans\",\"Helvetica\",\"Arial\",sans-serif";
const displayStyle = "inline-block";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "24px"
    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: font,
        fontSize: "16px",
        color: "#000000",
        lineHeight: "26px"
    },
    textStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "16px",
        lineHeight: "26px",
        fontStyle: "normal",
        color: "#000000"
    },
    InLine: {
        display: displayStyle,
    },
    InLineLabel: {
        fontWeight: "500",
        display: displayStyle,
        fontSize: "16px",
        lineHeight: "26px",
        fontFamily: font,
        paddingRight: "5px",
        color: "#000000"
    },
    showIcon: {
        display: displayStyle,
        marginLeft: "-25px",
        marginTop: "-20px"
    },
    headerStyles: {
        fontWeight: "500",
        fontFamily: font,
        fontSize: "32px",
        lineHeight: "42px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    mainHeader: {
        fontFamily: font,
        fontSize: "26px",
        fontWeight: "600",
        lineHeight: "36px",
        textAlign: "left",
        color: "#282B3E"
    },
    subHeader: {
        fontFamily: font,
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "24px",
        textAlign: "left",
        color: "#282B3E",
        letterSpacing: "0px",
        textAlign: "left",
        marginTop: "16px"
    },
    content: {
        fontFamily: "Manulife JH Sans",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "left",
        marginTop: "16px"
    },
    blueUnderline: {
        textDecoration: "none",
        borderBottom: "2.0px solid #0000C1",
        color: "blue",
        fontWeight: "600"
    },
}))



const ElectronicTCModalPage = props => {

    const classes = useStyles();
    const [allValues, setAllValues] = useState({
        ssnVisible: false,
    });
   
    const handleShow = (name) => {
        setAllValues({ ...allValues, [name]: !allValues[name] })
        //
    }

    return (<>
        <Grid container spacing={0}>
            <Grid item xs={12} className={classes.gridSeparation}>
                 <span className={classes.mainHeader}>Terms & Conditions</span>
                 <div className={classes.subHeader}>CONSENT TO ELECTRONIC DELIVERY & SMS/TEXT MESSAGING</div>
                 <div className={classes.subHeader}>Electronic Delivery</div>
                 <div className={classes.content}>By agreeing to this Consent to Electronic Delivery, you consent to receive the selected communications, notices and disclosures related to your policy or contract, including those that are otherwise required by applicable law to be provided to you in writing (the “Communications”), electronically from John Hancock Life Insurance Company (U.S.A.) and its affiliates (“John Hancock”), and you agree to the following terms.</div>
                 <div className={classes.content}>
                    <ul>
                        <li> You will receive emails with hyperlinks to websites and attached files from John Hancock when a Communication is electronically delivered and waiting for you.</li>
                        <li> When you receive an email from us, you should promptly read the email and, if applicable, access the link included in the email to the site with the Communication. From time to time, we may send important and time sensitive materials via an email alerting you to such materials. Your rights relating to your insurance coverage may be dependent on when you receive information from us. You will be considered to have received a Communication from us when we notify you at the email address you provided alerting you that it is waiting for you. Therefore, you should not block our emails and be diligent in updating your email address with us if it changes, which you can do as described below.</li>
                        <li> You confirm that you have the required hardware and software described below under the heading “Hardware and software requirements” and the ability to access, download, save and/or print Communications made available to you. While we do not charge a fee for providing electronic Communications, you may incur internet access charges and other third-party charges when receiving electronic Communications or downloading required software. Your ability to receive and access Communications may be limited due to circumstances beyond our control, such as system outages.</li>
                        <li> You confirm that you have a valid e-mail account that you are able to access. You have the sole responsibility of providing John Hancock with a correct and operational email address. John Hancock will not be liable for any blocked or undelivered email Communications. You must promptly notify us of any change in your email address, which you can do by contacting us as described below.</li>
                        <li> It is your responsibility to print (or download on your own device) and maintain a copy of all Communications delivered to you.</li>
                        <li> John Hancock may discontinue sending paper Communications to you upon receiving this Consent to Electronic Delivery; however, John Hancock may elect to send certain Communications to you via the US Postal Service if required by law, or as determined by us in our sole discretion. Therefore, you should be diligent in updating your US postal address with us if it changes, which you can do as described below.</li>
                        <li> This consent will remain in effect until you withdraw your consent as described below.</li>
                    </ul>
                 </div>
                 <div className={classes.subHeader}>Hardware and software requirements</div>
                 <div className={classes.content}>To access, download, save and/or print Communications that are electronically delivered, you must have all of the following:</div>
                 <div className={classes.content}>
                    <ul>
                        <li> A computer or other device with access to an internet browser currently supported by its publisher and that John Hancock supports.</li>
                        <li> An account with an internet service provider, access to the internet and an email address with the ability to send and receive emails with hyperlinks to websites and attached files.</li>
                        <li> Adobe Acrobat* Reader on your device if your internet browser does not have a built-in Adobe Acrobat* Portable Document Format (PDF) viewer. If you don't have Adobe Acrobat Reader on your computer, it can be obtained, free of charge, by clicking on the link below. <a className={classes.blueUnderline} rel="noopener noreferrer" href="https://get.adobe.com/reader/" target="_blank" >Adobe Reader Download</a></li>
                        <li> Local, electronic storage capacity to retain Communications and/or a printer to print Communications.</li>
                    </ul>
                 </div>
                 <div>
                    <span className={classes.content} style={{fontWeight: "300"}}>*Adobe, the Adobe logo and Acrobat are either the registered trademarks or trademarks of Adobe Systems, Incorporated in the United States and/or other countries.</span>
                    <div className={classes.subHeader}>Withdrawing your consent</div>
                 </div>
                 <div>
                    <div className={classes.content}>To withdraw your consent to receive future Communications from us electronically, you can go to  <a className={classes.blueUnderline} rel="noopener noreferrer" href="https://www.johnhancock.com/index.html" target="_blank" >www.johnhancock.com</a> and update the information within the customer portal or contact us as described below. Please note any withdrawal of a consent related to tax forms must be in writing either within the customer portal or by mailing your withdrawal to us at John Hancock Service Center, 410 University Avenue, Westwood, MA 02090 and cannot be completed by calling the customer service phone number noted below. <br/><br/>Any request to withdraw consent will become effective as soon as we have a reasonable opportunity to act upon it. In addition, at our option, John Hancock may revoke your consent and set your delivery preference back to US mail for instances such as: policy or contract termination, email message returned as undeliverable, or change or death of the policy or contract owner.</div>
                    <div className={classes.subHeader}>Getting paper copies</div>
                    <div className={classes.content}>You have the ability to print a paper copy of all Communications we deliver to you electronically. If you wish for us to send you a paper copy of any Communication at no charge, you may contact us as described below.</div>
                    <div className={classes.subHeader}>Contacting us</div>
                    <div className={classes.content}>You can change your US postal address, email address or phone number, or withdraw your consent to receive future Communications electronically, by going to <a className={classes.blueUnderline} rel="noopener noreferrer" href="https://www.johnhancock.com/index.html" target="_blank">www.johnhancock.com</a> and updating the information within the customer portal. You can also contact us by calling the customer service phone number for the applicable product below.</div>
                    <div className={classes.subHeader}>Customer service phone numbers</div>
                    <div className={classes.subHeader}>
                    <p>Annuities<br/>
                    800-344-1029</p>

                    <p>Life Insurance<br/>
                    800-732-5543</p>

                    <p>Long-Term Care Insurance <br/>
                    800-482-0022</p>

                    SMS/text Messaging
                    </div>
                    <div className={classes.content}>John Hancock Life Insurance Company (U.S.A.) and its affiliates (“John Hancock”) also offers single-message and subscription Short Messaging System (SMS) / text message services to its customers related to your John Hancock policy, account(s) servicing and transactions, billing/payment-related purposes, claims, and product information. You must be a customer of John Hancock as well as a registered user on our website in order to be eligible to receive SMS text messages related to your policy or account.<br/><br/>By providing your telephone number and opting-in to the SMS service, you: </div>
                    <div className={classes.content}>
                        <ul>
                            <li>Represent that you are the owner, or authorized user of the wireless device used to receive recurring SMS messages from John Hancock, that you are authorized to approve the applicable charges, and if you change your mobile phone number, you will promptly notify John Hancock of any such change in number,</li>
                            <li>Expressly consent to receive SMS/text messages from John Hancock, which may be delivered using an automatic telephone dialing system or an automatic texting system,</li>
                            <li>Understand that message frequency will vary. You could receive up to 8 text messages per month depending on your account activity,</li>
                            <li>Consent to receive text messages sent directly to your mobile phone even if your telephone number is on a company, state, or national Do Not Call registry,</li>
                            <li>May opt-out or stop receiving messages at any time (send STOP),</li>
                            <li>Acknowledge that consent to receiving text messages is not a condition to purchase any goods or services and that SMS / text messages and voice messages carry certain risks. For example, messages may be sent in unencrypted form. They could be viewed by others if they have access to your device or if your messages are sent to another device. View our privacy policy at <a className={classes.blueUnderline} rel="noopener noreferrer" href="https://www.johnhancock.com/privacy-security.html" target="_blank">www.johnhancock.com/privacy</a>, and</li>
                            <li>Understand that while John Hancock does not charge for this service, your wireless carrier’s standard messaging rates apply to SMS correspondence. You remain responsible for any and all charges, including, but not limited to, fees associated with text messaging imposed by your wireless carrier. Contact your wireless carrier for information about your messaging plan.</li>
                        </ul>
                    </div>
                    <div className={classes.content}>If you need assistance receiving SMS messages, reply with the word “HELP” for assistance or call the customer service toll-free number for the appropriate product listed above under the heading “Contacting us”.<br/><br/>John Hancock may modify or terminate its SMS/text messaging services from time to time, for any reason and without notice, including the right to terminate SMS/text messaging without notice, without liability to you or a third party. John Hancock makes no representations or warranties whatsoever regarding SMS/text messages or this service which is provided on an “as is” and “as available” basis, without express or implied warranties of any kind.</div>
                    <div className={classes.subHeader}>Withdrawing your consent</div>
                    <div className={classes.content}>You can cancel the SMS service at any time. Just text “STOP” to the short code (78294). After you send the SMS message “STOP” to us, we will send you an SMS message to confirm that you have been unsubscribed and you will no longer receive SMS messages from us.</div>
                 </div>

            </Grid>         
           
           
        </Grid>
    </>
    );
};

export default ElectronicTCModalPage;