import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ReactComponent as Logo } from '../../images/logo.svg';
import JsonConfig from '../../utils/jsonConfig.json';
import { VITALITY_LOGO } from "../../utils/Images";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    logoSeparation: {
        paddingTop: "30px",
        paddingBottom: "60px",
        // [theme.breakpoints.up("md")]: {
        //     paddingBottom: "80px",
        // },
    }
}))

const Header = props => {
    const classes = useStyles();

    const getVitalityLoginUrl = ()=> {
        const REACT_APP_VITALITY_URL = process.env.REACT_APP_VITALITY_URL;
        if (REACT_APP_VITALITY_URL.startsWith("https://test.johnhancockvitality.com") ||
            REACT_APP_VITALITY_URL.startsWith("https://stage.johnhancockvitality.com") ||
            REACT_APP_VITALITY_URL.startsWith("https://johnhancockvitality.com")) {
            return REACT_APP_VITALITY_URL;
        }
        
        return "";
    }

    return (
        <div data-testid="header-root" id="header-root" className={classes.root}>
            <Grid container spacing={0}>
                <Grid id="header-logo" item xs={12} className={classes.logoSeparation}>
                    {
                        props.appId === JsonConfig.app_id.VITALITY ?
                            <a href={getVitalityLoginUrl()}>
                            <img width={265} src={VITALITY_LOGO} alt="Vitality"/>
                            </a> :
                            <Logo/>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default Header;