import React, { useEffect } from 'react';
import { Modal, BREAKPOINTS } from '@manulife/mux';
import { Cross } from '@manulife/mux-cds-icons';
import { getQueryParams } from '../utils';
import JsonConfig from '../utils/jsonConfig.json';



let { appId } = getQueryParams();


const VideoGuideModal = (props) => {

    let {isVideoModalVisible, setVideoModalVisible} = props;

    useEffect(()=>{
      // Added this dynamic CSS to handle Video Modal alignments
      if(isVideoModalVisible){
        let videoModalEle = document.getElementById("video-modal");
        videoModalEle.style.overflow = "hidden";
        videoModalEle.style.border = "none";
        videoModalEle.style.boxSizing = "none";
        videoModalEle.style.background = "none";
      }
    }, [isVideoModalVisible])

    return (
      <Modal
        id="video-modal"
        isOpen={isVideoModalVisible}
        onClose={() => setVideoModalVisible(false)}
        showCloseButton={false}
        ariaLabel="Timeout Modal"
        customStyle={{
          modalStyle: {
              height: '50vh',
              width: '65%',
              padding: "0px",
              margin: "0px",
              border: "0px solid !important",
              background: "none !important",
              lineHeight: "0px",
              overflow: "hiddden !important",
              media: [{
                  maximum: BREAKPOINTS.PHONE_PORTRAIT_MED,
                  width: '100%',
                  height: "100%"
              }]
          }
      }}
        ariaLabelledBy="Registration-Guide-Video"
        ariaDescribedBy="content"
      >
          <Cross onClick={()=> setVideoModalVisible(false)} color="#fff" style={{ width: "24px", height: "24px", position: "absolute", top: "0.5rem", right: "0.5rem", cursor: "pointer", background: "none", borderStyle: "none", padding: "1rem",}} />
          <iframe
            title='registration-guid-video'
            id='registration-guid-video'
            aria-label="Registration Guide Video"
            height="100%"
            width="100%"
            src={JsonConfig.video_guid_url[appId]}
            referrerpolicy="no-referrer"
            allowfullscreen=""
            allow="fullscreen"/>
      </Modal>
    );
};

export default VideoGuideModal
