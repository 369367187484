import axios from 'axios';

const ajaxUsername = (userName, email, firstName, lastName, setErrorState, formValues, setFormValues, setShowSignIn, errorState, systemErrorCount, setSystemErrorCount, onUsernameResponse) => {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true
    };
    axios.post(
        process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/validateUsername` : 'http://localhost:8080/validateUsername',
        {
            "UserName": userName,
            "Email": email,
            "FirstName": firstName,
            "LastName": lastName
        },
        { headers }).then(response => {
            onUsernameResponse(response, null);
            if (response.data.code === "200") {
                if (errorState.userName.errMsg === "User ID is already taken") {
                    setErrorState({ ...errorState, userName: { id: 'userName', errMsg: '', isError: false } })
                }
                if (errorState.userName.errMsg === "Encountered Error") {
                    setErrorState({ ...errorState, userName: { id: 'userName', errMsg: '', isError: false } })
                    setFormValues({ ...formValues, usernameAjaxCallfail: false })
                }
                setShowSignIn(false)
            } else if (response.data.code === "9001" || response.data.code === "9014") {
                setErrorState({ ...errorState, userName: { id: 'userName', errMsg: 'Encountered Error', isError: true } })
                setFormValues({ ...formValues, usernameAjaxCallfail: true })
                setSystemErrorCount(systemErrorCount + 1);
            } else {
                setErrorState({ ...errorState, userName: { id: 'userName', errMsg: 'User ID is already taken', isError: true } })
                setShowSignIn(true)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            onUsernameResponse(null, error);
        })

}

export default ajaxUsername;
