import axios from 'axios';
import { getQueryParams } from '../utils';

 const multilifeRegister = (formValues, setFormValues, setErrorScenario, systemErrorCount, setSystemErrorCount, setPage, setIsAwaitingApiCall, setErrorType, setGuid, setDtcRegisterError, setAlreadyRegistered, setAnnuities, setLinkExpired, setPhoneNumberPresent, setAuthenticationFlow) => {
    
    let { jwt } = getQueryParams();

    return new Promise((resolve, reject) => {
        
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': true
        };
        setIsAwaitingApiCall(true)
        axios.post(
            process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/multiliferegister` : "http://localhost:8080/multiliferegister",
            {
                "appid": formValues.appId,
                "jwt": jwt
            },
            { headers }).then(response => {
                setIsAwaitingApiCall(false)
                console.log(response.data.message)
                let errorPageCodes = ["9009", "9027", "9041", "9048", "9036", "9037", "9046", "9047", "9011", "9055", "9038", "9042", "9057", "2002", "9060"];
                let dtcErrorCodes = ["9050", "9040", "9028"];
                let systemErrorCodes = ["2003", "9007", "9008", "9053", "9031", "9032", "9014", "9077", "9016"]
                if (errorPageCodes.includes(response.data.code)) {
                    setErrorScenario(true)
                } else if (response.data.code === "9035") {
                    setAlreadyRegistered(true)
                } else if (dtcErrorCodes.includes(response.data.code)) {
                    setDtcRegisterError(true)
                } else if (response.data.code === "3000") {
                    setAuthenticationFlow(true);
                    setPage(4);
                    setGuid(response.data.details.requestid);
                } else if(response.data.code === "2001"){
                    setLinkExpired(true);
                } else if (systemErrorCodes.includes(response.data.code)) {
                    setSystemErrorCount(systemErrorCount + 1)
                    setPage(-1)
                } else if (response.data.code === "9054") {
                    setAnnuities({isVisible: true, from: "UserInfoPage"});
                } else if (response.data.code === "1001") {
                    console.log("User verified: Fresh registration")
                    setGuid(response.data.details.requestid)
                    let data = response.data.details;
                    setFormValues({...formValues, ...{firstName: data.firstName, lastName: data.lastName, phoneNumber: data.phoneNumber ? data.phoneNumber : ""}});
                    setSystemErrorCount(0);
                    setPhoneNumberPresent(data.phoneNumberPresent);
                    setPage(1)
                } else if (response.data.code === "9056") {
                    console.log("redirectUrl==", response.data.details?.registrationlink);
                    let url = response.data.details?.registrationlink;
                    if(url?.startsWith("https://crverifyidentity.registration-dev.johnhancock.com") || 
                        url?.startsWith("https://crverifyidentity.registration-tst.johnhancock.com") ||
                        url?.startsWith("https://crverifyidentity.registration-uat.johnhancock.com") ||
                        url?.startsWith("https://crverifyidentity.registration.johnhancock.com") ||
                        url?.startsWith("http://localhost:3001")){
                        window.open(url, "_self");
                    }
                } else {
                    setPage(1)
                }
                resolve(true);
            }).catch(error => {
                console.log("Error ========>", error);
                setIsAwaitingApiCall(false)
                if (error.message === "Network Error") {
                    setErrorType("Technical")
                } else{
                    setSystemErrorCount(systemErrorCount + 1)
                }
                resolve(true);
            })
    });
};

export default multilifeRegister;
