import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './Style';
import {getQueryParams} from "../../utils";
import JsonConfig from '../../utils/jsonConfig.json';

export default function EncounteredError(props){

    let { appId } = getQueryParams();

    
    let supportNumber = JsonConfig.call_center_number[appId] ? JsonConfig.call_center_number[appId] : JsonConfig.call_center_number.default;
    let validatePhoneNumberRegex = /^[0-9\-/?]*$/;

    const classes = useStyles();
    return <Grid item id="unknown-error-message" md={9} xs={12}>
                <div className={classes.errorWrapper}>
                    <div className={classes.errorIcon} />
                    {props.systemErrorCount === 1
                        ? <span className={classes.errorLabelStyle}>
                            We have encountered an error. Please try again.
                        </span>
                        : <span className={classes.errorLabelStyle} >
                            We're sorry, we are currently experiencing some system issues. Please call <a className={classes.errorLabelStyle} href={`tel:${validatePhoneNumberRegex.test(supportNumber) ? supportNumber : ""}`}>{supportNumber}</a> to speak with a John Hancock representative and we will assist you as best we can.
                        </span>}
                </div>
            </Grid>
}